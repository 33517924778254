import React from "react"

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"

const PaymentMethods = () => {
    return (
        <>
            Displaying Stored cards in headless is not available at this time.
        </>
    )
}

const PaymentMethodsOutput = () => (
    <AccountPageWrapper metaTitle="Account - Payment Methods" title="Payment Methods">
        <PaymentMethods />
    </AccountPageWrapper>
)

export default PaymentMethodsOutput